import React from "react";
import { Link } from "gatsby";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import Section from "../components/Section";
import { Text } from "../components/Core";
import PageWrapper from "../components/PageWrapper";

const FeatureFarmPage = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Section>
          <div className="pt-5"></div>
          <Container align="center">
            <Text>
              Having trouble viewing on mobile?{" "}
              <a href="https://forms.gle/mLbWEmWenRjktpP3A">View here.</a>
            </Text>
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLScYOaK06h484-gUaRjdhJEgAQ-GLk2vHPTcWiVrAdJK1jzZrg/viewform?embedded=true"
              width="640"
              height="1472"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
            >
              Loading…
            </iframe>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};

export default FeatureFarmPage;
